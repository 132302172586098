@use '../../styles/mixins' as mixins;

.accordion-module {
    background-color: var(--wp--preset--color--bnpp-green-tint-15);
    max-width: 800px;

    h3 {
        font-family: var(--wp--preset--font-family--bnpp-sans);
        font-size: var(--wp--preset--font-size--medium);
        margin: 0;
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: var(--wp--preset--spacing--40);
        position: relative;
        padding: var(--wp--preset--spacing--60);

        .toggle-icon{
            position: relative;
            --iconSize: 12px;
            width: calc(var(--iconSize) * 2);

            &::before, &::after {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                right: 50%;
                width: var(--iconSize);
                height: 1px;
                background-color: currentColor;
                border-radius: 50px;
                border: solid 1px;
                transition: 300ms;
            }

            &::before {
                transform: translateX(-5px) rotate(35deg);
            }

            &::after {
                transform: translateX(4px) rotate(-35deg);
            }

        }
    }


    .accordion-module-text {
        margin: 0;
        max-height: var(--maxHeight, 0px);
        padding: 0 var(--wp--preset--spacing--60);
        overflow: hidden;
        transition: 300ms;
        font-weight: 300;
    }

    &.isOpen {

        .toggle-icon {

            &::before {
                transform: translateX(-5px) rotate(-35deg);
            }

            &::after {
                transform: translateX(4px) rotate(35deg);
            }

        }
    }
}